<template>
  <div class="agrement-policy-container">
    <div v-if="$route.query.type == 'agreement'">
      <div
        class="Section0"
        style="layout-grid:15.6000pt;"
      >
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">欢迎您使用</font>Meta彼岸的产品和服务！&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本用户服务协议适用于您通过任何方式对</font>Meta彼岸各项服务的使用。Meta彼岸是一款虛拟空间多人互动与交流应用
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">社区</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，</font>&nbsp;<font face="宋体">您可以通过</font>Meta彼岸使用虛拟空间多人互动
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">语音对话、</font>Al捏脸等服务（以下统称"Meta彼岸产品或服务)。您具体获得的Meta彼岸服务内容可能因为您使用的Meta彼岸的版本不同而有所差异。您可以通过多种不同的方式来使用我们的产品和服务，包括Meta彼岸的软件、供第三方网站和应用程序使用的Meta彼岸软件开发工具包(SDK)&nbsp;和应用程序编程接口&nbsp;(API)等&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">一、【重要</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">须知</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">】</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.在您开始使用Meta彼岸产品或服务前，请您务必仔细</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">阅读</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">并充分理解本《</font>Meta彼岸用户服务协议》、您开通或使用某项服务时的附加条款以及智度
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">不时发布或修订的相关条款</font>&nbsp;<font face="宋体">（统称为</font>&#8220;本用户服务协议&#8221;）。同时，您还应仔细
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">阅读</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">并充分理解《</font>Meta彼岸隐私政策》。在本用户服务协议中，请您务必特别注意及重点
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">阅读</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">与您的权利及义务</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">密</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">切相关的条款，包括但不限于免除或者限制</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">责任</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">的条款。</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.一旦您开始使用Meta彼岸服务，即代表您己理解</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">并</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">同意本用户服务协议及系列</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">声明</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，本用户服务协议及智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的系列声明将构成对您（和您代表的其他主体）具有约束力的法律文件，同时还表明您己完全同意</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">并</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">承认智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">已经履行了相关的提示和通知义务。您确保您有充分的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">权利</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、权限及能力以同意及履行本用户服务协议。如果</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">代表其他主体同意本用户服务协议，您确保您已获得充分的法律授权以使您代表的其他主体接受本用户服务协议的约束。如果您</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">未</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">获得充分的授权，请确保由其他主体的授权人士</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">同意</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本用户服务协议。</font>
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">如果您不同意本用户服务协议，</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">请勿</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">使用</font>Meta彼岸服务
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.&nbsp;Meta彼岸是一款面向成年人的产品和服务，若您是末满18周岁的未成年人，在使用Meta彼岸产品和服务前，应请您的监护人仔细阅读或在您的监护人的监护、指导下共同仔细问读本用户协议及《Meta彼岸隐私政策》，并在征得您的监护人同意的前提下使用我们的产品和或服务或向我们提供信息。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">有权不时对本用户服务协议进行修订，如果本用户服务协议发生变动，智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">将在</font>Meta彼岸服务的相关软件及网站上公布修订后的用户服务协议。您理解
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">并</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">同意，如果您在修订后的用户服务协议公布</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">并</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">生效后继续使用</font>Meta彼岸服务，即视为您已经理解并同意修订后的用户服务协议。
            </span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">二、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">使用</font>Meta彼岸服务&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.服务介绍</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(1)</span><span style="mso-spacerun:'yes';font-family:等线;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">MMeta彼岸服务是智度</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">及其关联公司研发或已得到相应权利人授权的包括虛拟空间多人互动</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">语音对话、手势操作</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、数字藏品的展示、浏览、购买</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">等相关的服务，具体服务内容由智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">按照</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">现状</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8221;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">向您提供。</font>
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">有权自行确定</font>Meta彼岸服务的形式和
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">具体</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">内容，智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">有权自行变更、中止或</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">终止</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸的部分</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">或</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">全部服务，有权发布新版本。</font>
            </span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2)您在使用需要连接互联网便用的服务时可能会消耗网络流</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">量</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，由此产生的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">流量</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">费等</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">费用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">由您的电信服务提供商收取，具体收费标准请向您的电信服务提供商咨询</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(3)</span><span style="mso-spacerun:'yes';font-family:等线;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸的服务区域仅为中国大陆地区，智度</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">不承诺</font>Meta彼岸服务对中国大陆地区以外的任何国家
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">地区或地点均可便用。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.账号注册及维护&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1)您在使用Meta彼岸时需注册账号</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">登录</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸，如您选择通过Meta彼岸平台注册账号，请您按照页面</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">提示</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">完成账号注册</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">程序</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">。在注册成功后，您将开通一个用户账号，请您自行负责保管。如果您已拥有账号，</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">可以通过您的账号直接登录</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(2)在账号</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">注册</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">过程中，您应当按照法律法规要求，根据页面提示准确提供账号</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">注册</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">所需个人资料，包括但不限于账号名称、昵称、密码、电子邮箱、手机号码等（统称为</font>&#8220;个人资料）。
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">您保证您提供的个人资料均真实、完整、准确，并在注册资料发生任何交更时，及时更新最新资料。如有我们合理理由认为您提供的个人资料不实、不</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">完整</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、不准确，或者未及时</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">更新</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">的，智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">有权向您询问</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">并</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">发出要求更正的通知，并有权自行刪除相关资料，直至智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">暂停、终止对您服务的部分或全部功能。您自行承担因此产生的全部后果和损失，智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">对此不承担责任。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(3)请您自行妥善保管您的账号、</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">密</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">码及其他注册资料，并对您的用户账号下发生的一切行为承担责任。如果由于您未能妥善保管账号、密码及其他注册资料而使您自己、智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、其他用户或任何第三方造成损害，您自行承担因此产生的全部后果和损失。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4)</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">您所注册账号的所有权归</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">属于</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">，使用权仅</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">归属</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">于您，除非有其他合法</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">事由</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">，并经过智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">同意，您不得将账号</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">赠予</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、转让、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">售卖</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、借用或租用给他人。</font>
            </span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如果发生前述事项，您同意自行承担因此产生的全部后果和损失，智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">对此不承担责任，并保留追究相关行为人法律</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">责任</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的权利。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(5）如果发现您的账号存在未获授权的使用，或者其他可能</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">危害</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您的账号安全的情形时，您应立即修改密码，并立即以有效方式通知智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，要求智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙暂停</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">相关服务。您理解智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">对您的请求采取行动需要</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">合理</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">时间，智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担法律法规明确规定之外的责任。</font>&nbsp;<font face="宋体">对于非因智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的原因导致他人利用您的账号所进行的任何行为及因此而产生的后果或损失，请您向相应责任人索赔，智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">将提供必要的合理协助。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(6)如果您遗失账号或</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">遗忘密码</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，您应当按照智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">提示的途径申清找回账号或修改密码。您应当妥善保管注册资料与密码保护资料，</font>
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">如果由于您捉供的资料不真实、不完整、不准确、不真实或由于智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">以外的原因导致您的资料被他人知悉，您可能无法找回账号或修改密码。智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">通过识别您所提供资料与系统保存资料的一致性进行判断，智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">不保证您一定可以通过前述方式找回账号或修改密码。</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.使用许可&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(1)为智度</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">提供服务及您使用</font>Meta彼岸服务的目的，智度
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在此</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">授予</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您一项</font>
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">个人的、不可转让的、不可转授权的、非排他性的、可</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">撤销</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">的普通便用许可</font>
            </span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，以便得您能够以本用户服务协议许可的方式使用</font>Meta彼岸相关股务，用于您个人的非商业用途。
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">您保证您不允许其他人为商业目的而使用</font>Meta彼岸服务，从
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">而</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">直接或间接获得任何形式的收益，如果您有商业需要，请您及时联系智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">，</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">事先</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">获得智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">的书面许可，并与智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">另行签订书面协议。</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2)本用户服务协议未明确的其他一切权利归智度</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">所有，本用户服务协议未</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">授予</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您任何其他未明示的权利。</font>&nbsp;<font face="宋体">如您使用其他权利，您需要及时联系智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，事先获得智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的书面许可，并与智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">另行签订书面协议。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.第三方服务&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1)</span><span style="mso-spacerun:'yes';font-family:等线;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸服务可能会以外部链接、&nbsp;服务接口等形式，指向或包含任何第三方所提供的网站、产品、服务或其他任何内容（统称为"第三方服务&#8221;)。第三方服务均由第三方公司或机构（统称为&#8220;第三方&#8221;)&nbsp;握供，</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">对第三方服务及其任何内容不提供任何担保或保证。第三方在提供服务的过程中向您作出的任何承诺、保证及任何其他行为均为第三方行为，与智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">无关，智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">不会对第三方的服务和行为</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">负责</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">，且在任何情况下不会对第三方服务及其行为承担法律责任。您理解</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">并</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">同意，您在使用第三方服务时，应仔细</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">阅读</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">并遵守第三方的服务条款、隐私权政策或其他第三方要求您遵守的任何法律文件。</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(2)</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">为了保障您的利益，请您在使用第三方服务前审慎判断并决定是否使用。</font>
            </span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您理解并同意，您是否使用第三方的产品或服务均由您自行判断、自愿选择，您应了解</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">并</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">清楚由于使用第三方服务所可能引发的任何风险和后果。</font>
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">如果您由于使用第三方服务而发生任何纠纷、后果、损失或其他问题，请您与第三方协商解决，</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">智度宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">对此不承担责任，但会向您提供法律法规要求的必要</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">的</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">信息或协助</font>.&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(3)</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">你理解并清楚，</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">使</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">用第三方服务可能需要您向第三方支付费用，如果您不</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">同意</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">支付该费用，请勿便用此类服务。您理解并清楚，使用部分第三方服务，需要您向第三方提供其要求的个人信息，一旦您使用此类服务，即表示您同意智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">将您相应的个人信息发送至第三方，以使其能够向您提供服务。智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">对于第三方的信息</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">收集</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、信息使用、信息保护及其他有关个人信息的事项无法监督和控制，由于第三方的原因导致您的个人信息遭受任何侵害的，您应向第三方主张权利，智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">对此不提供任何担保和保证，不承担任何法律贵任。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">5.信息发送</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如果您使用</font>Meta彼岸服务，即表示您同意智度
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在相关的软件和网站中向您发送消息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">包括但不限于活动、促销、</font>&nbsp;<font face="宋体">广告、宣传或其他商业信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，并同意智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">通过您提供的手机号码、电子邮件或您提供的其他联系方式发送前述消息，如果您不再同意智度</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">向您发送上述</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">消息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">请您</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">以退订短信、电子邮件或以其他书面形式明确告知</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">6.广告</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宣传</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1)您理解并同意，智度</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">拥有通过</font>Meta彼岸服务的相关软件或网站向您关联或展示广告的权利，并对广告投放行为不承担通知义务。您不得以任何方式改变、移除或遮蔽这些广告，亦不得以任何形式利用智度
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">关联或</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">展示</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">任何广告。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(2）</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">理解并清楚，您对</font>Meta彼岸服务中关联或展示的广告应当审慎判断，您通过Meta彼岸服务与广告商进行任何形式的通讯或商业往来，属于您与广告商之间的行为。如果您因前述任何行为而遭受的任何性质的后果或损失，您应向有关广告
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">商</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">主张权利。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">三、用</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">户</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">行为要求</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">信息</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">内容要求</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1)</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">您声明并</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">保证</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">，您对您通过</font>Meta彼岸服务发布
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">或</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">以其他方式向公众</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">或</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">其他用户可访问的</font>MMeta彼岸服务中区域内提供的任何信息，包括但不限于文字、
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">声音</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">图片</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、视频、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">音频</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">等信息</font>(统称为
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">用户内容</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8221;</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)，拥有合法</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">权利</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">或有效的许可或授权。否则，智度</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">有权自行决定对您的用户内容屏藏或删除。您理解并同意，智度宇宙不承担必须公布您提供的用户内容的义务，除另有约定之外，智度宇宙对您提交的用户内容不会提供任何</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">报</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">酬。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(2)您理解并同意，您对您通过</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">服务所提交内容的真实性、准确性、完整性、及时性负责，并且该等内容不违反任何法律法规，不侵犯任何第三方权益，不涉及或</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">鼓励</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">侵权行为或其他非法行为。否则，您应自行承担因此产生的责任和后果。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.服务使用要求</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(1)您理解并同意，Meta彼岸服务需要结合相关设备使用，您保证您为所使用设备的合法所有者或使用者，否则您应当立即停止使用</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">产品并将设备归还给设备所有人。如果您对您使用的设备进行任何形式的刷机、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">拆卸、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">改装等可能影响设备性能的提作，可能会导致</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的部分或全部服务无法使用，你理解并自行</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">承担此风险</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，智度宇宙对此不承担任何责任。如果您将您使用的设备</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">赠予</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、转让、售卖、借用或租用给他人，您应当充分告知</font>Meta彼岸服务的存在及本用户服务协议的内容，您务必在交付设备前
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">退出</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您使用的账号。否则，由此产生的任何后果和损失均由您自行承担责任，智度宇宙对此不承担任何责任</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(2)</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">您理解并同意，在使用</font>Meta彼岸服务时，您应遵守所有适用的法律法规、规章、规范、政策、行政命令及社会公序良俗。您在使用Meta彼岸服务过程中，不得以任何方式实施，也不得同意、授权或指示任何其他主体从事包括但不限于下列任何行为:&nbsp;
            </span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">a)反对宪法所确定的基本原则的行为</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">b)宣扬或实施危害国家主权</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">国家安全</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">国家秘密</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">国家统一</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">国家荣誉</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">国家利益、民族关系、民族团结</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">社会秩序</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">社会稳定</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">公序良俗的行为</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">c)宣扬或实施煽动民族仇恨或民族歧视的、破坏民族团结的、破坏国家宗教政策的、宣扬邪教和封建迷信的、散布谣言的、扰乱社会秩序的、破坏社会稳定的、侮辱的诽谤的、淫秽的、色情的、暴力的，恐怖的、教唆犯罪的行为</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">d)宣扬或实施含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害中伤、粗俗猥亵或其它道德上令人反感的内容的行为</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">e)宣扬或提供关于非法行为的说明信息</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">f)宣扬或实施侵害他人的人身财产</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">名誉权</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">肖像权</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">知识产权</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">商业秘密权等合法权益的行为</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">g)</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">上传</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、张贴、发表、发布、传送、传播、存储任何广告信息、垃圾信息、病毒或旨在危害计算机软硬件正常运转的代码、文档或其他任何违法信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">h)制作、发布、传播用于窃取他人的账号财产及个人信息的网站、软件或服务，或利用Meta彼岸服务跟踪、追踪、监视、骚扰或伤害他人</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">i)收集或存储有关其他用户的个人数据，用于法律法规或本用户服务协议禁止的行为或活动</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">j)进行任何可能对互联网或其他通信网络的正常运转造成不利影响的行为</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">k)对</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">进行修改、复制、发布、传输、公开展示展览、播放翻制、翻译、发行、出版、授权、从其创建衍生产品、转让、出售、逆向工程、反编译，或试图从</font>Meta彼岸服务或其任何部分提取源代码或获取原始数据
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">i</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)使用任何漫游器抓取工具网站搜索/检索应用程序或其他工具检索或索引Meta彼岸服务的任一部分，或出于任何未经授权的目的收集关于用户的信息</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">M)脱离Meta彼岸服务单独使用或展示相关内容，为了商业运营目的印刷包含相关内容截图的销售材料，或将相关内容作为核心内容整合到印刷品中</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">n)对</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">服务或者其运行过程中展示的任何号码数据或其他数据，以及</font>Meta彼岸服务客户端与服务器端的交互数据进行更改、修改或创作任何衍生作品，包括但不限于使用插件、外挂或非经授权的第三方工具或服务接入Meta彼岸服务
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">0)转让根据本用户服务协议授予的任何使用许可，或基于对Meta彼岸服务的使用权设置担保权益</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">p)将Meta彼岸服务以收费或其他营利方式进行提供，无论是否为直接经济或金钱收益</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">q)其他以任何不合法的方式为任何不合法的目的或以任何与本用户服务协议不一致的方式使用Meta彼岸服务或进行其他未经智度宇宙明确许可的行为。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.对自己行为负责&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(1)您理解并同意，您对自己使用Meta彼岸服务下的一切行为负责。</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">智度宇宙有权依据合理判断对违反法律法规或本用户服务协议的行为作出处理，并保留对该等违反行为采取法律所能提供的所有补救手段的全部权利。</font>
            </span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙有权对违反法律法规及本用户服务协议的任何用户调查并采取</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">适当</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的法律行动，包括</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">但不限于</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">民事诉讼</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">等。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙有权根据违法违规行为的严重程度，将上述违法违规行为的线索和您的个人信息报告司法机关或其他执法机关，并配合司法机关或其他执法机关进行的调查听证、起诉等。您自行承担由此产生的任何法律责任。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(2)您理解并同意，如果因为您违反法律法规或本用户服务协议而导致智度宇宙、其他用户或任何第三方造成损害，引起任何和全部索赔、请求、损失、责任和费用，您自行承担因此产生的全部后果和损失，包括但不限于罚款赔偿金、诉讼费律师费等，智度宇宙对此不承担任何责任。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">四、数字藏品购买和转赠规则</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">数字藏品的购买</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">您通过</font>Meta彼岸应用内数字艺术馆购买数字藏品，应当是已满1
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">8</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">周岁的中国大陆居民，并且已经通过平台实名认证流程。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">.</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">数字藏品的转赠</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">（</font>1）在平台规则或平台功能允许的情况下，您可以将所持有的数字藏品无偿转赠给平台的其他用户。您理解并同意，一旦发起转赠操作将无法撤销，且转赠成功后，与数字藏品相关的权利将同步且毫无保留的转移至受赠人。
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">（</font>2）您通过平台转赠数字藏品，应当满足以下必要条件：
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">a</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">您与受赠人均已通过平台的实名认证；</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">b</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">如果您是从数字藏品发行方处购买的数字藏品，您至少持有该数字藏品达到</font>5天后才能转赠。每次转赠数字藏品需支付0
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">.99</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">元上链服务费，试用期间</font>5折优惠。
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">（具体时限及相关规则可能不定期调整，我们将在平台进行及时更新和公告，请您留意平台公告信息，具体以公告信息为准）</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">.</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">您理解并同意，我们有权依据国家法律法规、监管政策、平台规则或业务需要对上述购买或转赠条件进行补充或修改。您与受赠人一旦点击页面</font>&#8220;同意&#8221;按钮确认本协议或平台规则，或者发起购买或转赠申请，即表示您与受赠人承诺符合购买/转赠条件并同意受到本协议及平台规则的约束。
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">.</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">特别说明，如您未满</font>1
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">8</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">周岁，自本服务协议版本生效之日起，我们将禁止您买入</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">应用内数字藏品，对于本版本服务协议生效前您已经严格按照服务协议要求购买的数字藏品，我们将持续为您提供转赠（仅限于您所持有的数字藏品的转出服务，不得作为受赠人接受其他用户转赠）的服务。除上述服务受限外，您所享有的</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">其他服务不受影响。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">五、数字藏品服务说明</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您有权在</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸数字艺术馆内享受数字藏品的浏览、购买以及数字藏品的访问、欣赏、信息查看</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">信息技术服务，具体服务内容和功能以平台页面向您实际展示为准。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您可以通过平台浏览发行方提供的数字藏品及相关信息。</font>
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">当您下单购买前，请您务必仔细确认所购买数字藏品的名称、介绍、发行方、价格、数量及</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/或其他限制性要求等重要事项，并对是否购买数字藏品做出独立决策和判断。一旦您提交订单，请在规定</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">时限内完成支付。您支付成功后即视为您与发行方之间就已支付价款部分的订单建立了合同关系。特别提醒本平台出售的数字藏品均为虚拟数字商品，而非实物，且因区块链技术的特性，本平台售出的数字藏品不支持七天无理由退还。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">.基于区块链技术的特性，数字藏品具有加密和不可篡改的特点。一旦上链，数字藏品元数据中的作者、创作时间和上链时间、购买者等信息，在链上无法篡改。您购买相关数字藏品后，您的购买者信息将记录在数字藏品的元数据中，作为您持有该数字藏品的权利凭证。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.您理解并同意，</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">不论数字藏品合同如何约定，数字藏品的版权、所有权（如有）由发行方或原作创作者拥有。除另行取得版权权利人的书面同意外，您不得将数字藏品用于数字藏品合同约定外的任何用途。您所购买或受赠所拥有的数字藏品应仅用于数字藏品合同约定的授权范围内自身收藏、研究、学习、欣赏、展示之目的，不得用于其他任何目的。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">5.我们严格</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">禁止您利用数字藏品进行售卖、炒作、场外交易、欺诈或以其他以转赠方式规避此条限制等任何非法方式进行使用。</font>
            </span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们保留随时根据业务发展和法律法规政策的变换调整您的权利。您认可并同意，为了保障交易安全、有序、合法及履行我们的反洗钱义务，我们有权对您的区块链地址进行安全查询。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">五</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、隐私政策</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙非常重视对用户个人信息的保护。智度宇宙将按照《</font>Meta彼岸隐私政策》的规定收集、使用、储存和分享您的个人信息。《Meta彼岸隐私政策》为本用户服务协议不可分割的组成部分。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">六</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、知识产权归属</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">智度宇宙的知识产权</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(1)</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">智度宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">拥有</font>Meta彼岸服务及相关内容的著作权、商标权、专利权等所有法定权利、资格和利益(不论这些权利是否已注册、不论这些权利可能存在于世界何处)，包括但不限于Meta彼岸产品中的号码数据、源代码、文档、商标、标志等。这些权利受著作权法、商标法、专利法、反不正当竞争法等相关法律法规和以及国际条约的&nbsp;保护。此外，经由Meta彼岸
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">服务</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">向您</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">呈现</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">的第</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">三</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">方服务，广告或其他信息所包含的内容，亦受到</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">著作权</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、商标权、专利权或其他法律保护。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(2)您对本用户服务协议的同意和对Meta彼岸服务的使用，不涉及上述任何知识产权的转让，上述全部知识产权仍归智度宇宙或相应权利人所有。未经智度宇宙和/或相应权利人事先书面同意，您不能自行实施、利用、转让或许可任何三方实施、利用、转让上述知识产权，您亦不能修改、租借、出售、制作衍生作品或以其他方式使用任何部分或全部上述知识产权。&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(3)您不得以任何方式删除、掩藏、修改或替换Meta彼岸服务中所附的或包含的任何专有权利声明，或智度宇宙及其合作伙伴的任何版权标识商标、服务标记、标识、商号、企业名称、域名、审图号、出版号及其他明显的标志。&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(4)除非您与智度宇宙或相应权利人另行达成书面协议，您不能使用智度宇宙及相应权利人的任何标志。本用户服务协议没有授予您以任何形式或目的使用Meta彼岸服务标志的权利，也没有授予您使用任何智度宇宙其他标志或任何第三方标志的权利。</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.您的知识产权&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(1)</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">您理解并同意，为持续改善智度宇宙为您提供的各项服务，除非有相反证明，您使用本平台服务上传、发布</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">或传输</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">内容即代表了您有权</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">且同意</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">在全世界范围内，永久性的</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">不可</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">撤销</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">的</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">免费的</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">授予</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">智度宇宙</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">及</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">关联方对该内容的存储、使用、发布、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示等权利</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">；</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">将内容的全部或部分编入其他任何形式的作品、媒体、技术中的权利</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">；</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">对您的上传、发布的内容进行商业开发的权利</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">；</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">通过有线或无线网络向计算机终端移动通讯终端</font>(包括但不限于便携式通讯设备如手机和&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">智能</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">平板电脑等</font>)、手持数字影音播放设备，电视接收设备(模拟
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">信号</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">接收设备，数字信号接收设备，数字电视、</font>IPTV
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">电视机顶盒</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">视频投影仪、车载电视、互联网电视</font>/OTT终端、智能电视等带互联网接入功能的播放设备等)，其他智能硬件终端(包括但不限于VR眼镜、AR眼镜、智能机器人，智能冰箱、智能音箱等)等提供信息的下载点播、数据传输、移动视频业务(包括但不限于SMS
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">MMS</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">WAP</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">IVR、Steaming、3G</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">手机视频等无线服务</font>)及其相关宣传和推广等服务的权利
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">；</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">以及再授权给其他第三方以上述方式使用的权利。</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(2)</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">您理解并同意，尽管有上述约定，您通过</font>Meta彼岸服务上报反馈、分享、添加、标注、纠正或以其他方式向智度宇宙提交号码信息、问题截图、纠错信息
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">问题反馈、改进建议或意见等内容或类似内容时，前述任何内容的全部知识产权，所有权及其他现行及未来法律规定的全部法律权利自您提交之时全部自动转让给智度宇宙并且智度宇宙无需向您支付任何费用。</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">七</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、权利保护</font>&nbsp;
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.若您认为智度宇宙或第三方利用</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">服务侵犯了您的合法权益，您</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">可以以</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">发送邮件的方式向智度宇宙提交权利通知及相关的支持性资料</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">【邮箱地址：</font>
          </span><span><a href="mailto:service@genimouschain.com"><u><span
                  class="18"
                  style="mso-spacerun:'yes';font-family:宋体;color:rgb(5,99,193);text-decoration:underline;text-underline:single;mso-font-kerning:0.0000pt;"
                >service@genimouschain.com</span></u></a></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">】</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，智度宇宙将会依照法律法规的要求进行外理</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.您理解并同意，如果您提供的权利通知的陈述失实，您将承担对由此造成的全部法律责任(包括但不限于赔偿各种费用及律师费)。如果您不确定网络上可获取的资料是否侵犯了您的合法权益，智度宇宙建议您应首先咨询专业人士。&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">八</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、陈述与保证</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.智度宇宙将按照适用的法律法规强制性标准、行业标准等向您提供Meta彼岸服务。您理解并同意，智度宇宙及其许可人不就以下各项向您提供明示或默示的担保或保证</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">：</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(1)Meta彼岸服务将符合您的实际或特定需求或目的</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(2)Meta彼岸服务的准确性、完整性或及时性</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(3)Meta彼岸服务将不受干扰、无中断、持续稳定或没有错误</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(4)使用Meta彼岸服务获得的任何结果或资料的正确性或可靠性</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(5)Meta彼岸服务中的任何错误问题、故障都将得到更正或解决</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(6)经</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸服务</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">购买或获得的任何产品、服务或其他内容符合您的期望</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(7)Meta彼岸服务中的第三方服务或外部链接及其指向的内容的准确性、稳定性和完整性</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(8)您提供给智度宇宙的信息不受任何侵害。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2您从</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">服务获得的任何建议或信息</font>(无论书面或口头形式)均不构成本用户服务协议明确规定以外的任何担保或保证。某些国家法律法规不允许排除部分担保或保证，因此，在适用的法律法规明文禁止此类排除的情况下，以上提到的部分担保或保证排除规定可能对您不适用。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">九</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、免责及责任限制</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.您理解并同意，Meta彼岸服务可能会受到多种因素影响，包括但不限于您的原因、网络设备维护、网络连接故障、网络信号未覆盖、网络传输延时或中断电子终端、通讯或其他系统的故障或误差、电力故障、罢工、劳动争议、暴乱、骚乱、火灾、</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">疫情、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令、其他不可抗力或第三方的不作为等不可抗力</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸服务也可能受到各种安全问题的侵扰，例如您下载安装的其它软件或访问的其他网站中含有病毒、木马或其他恶意程序，威胁到您的设备和数据安全，进而影响Meta彼岸服务的使用等。</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">除法律法规明确规定要求智度宇宙承担责任之外，因本款所述原因给您造成的任何损失由您本人自行承担智度宇宙对此不承担责任。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">.您理解并同意，除法律法规明确规定要求智度宇宙承担责任之外，您使用Meta彼岸服务及通过Meta彼岸服务获得任何商品、服务或内容所可能引发的任何风险和后果由您本人自行承担，智度宇宙对此不承担责任。在所有情况下，智度宇宙对于任何不能合理预见的损失或损害不承担责任。</span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.</span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸服务中可能包括其他用户或第三方提交的内容，提供此类不属于智度宇宙的内容仅为向您提供便利，该等内容由其发布者承担全部责任。智度宇宙可能会审查相关内容，以确定其是否违反法律法规或智度宇宙相关政策，且在智度宇宙有合理理由时将其删除或拒绝显示，但这并不意味着智度宇宙有义务或必然会审查该等内容。您对其他用户或第三方所提交内容的使用表明您同意您将自行对其进行评估，并承担与之相关的所有风险和后果。</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.智度宇宙作为服务提供商，根据相关法律法规的要求对非法转载虚假发布、盗版行为的发生会进行必要监控，但不保证会发现所有上述行为。除法律法规明确规定要求智度宇宙承担责任之外，智度宇宙对您或他人实施的此类违法或侵权行为不承担任何责任。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">十</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、服务的变更中止和终止</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙有权自行选择发出或者不发出通知，以变更中止或终止提供</font>Meta彼岸服务，也有权自行选择变更、中止或终止您对Meta彼岸服务的使用。如因服务器或系统的维护，升级需要或其他原因需要暂停成终止Meta彼岸服务的全部或者部分内容，智度宇宙将尽可能事先公告。如果智度宇宙永久终止提供Meta彼岸服务，智度宇宙将在服务终止前提前在相关软件或网站上公告，您同意智度宇宙无需单独通知您。
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">除法律法规明确要求智度宇宙承担责任之外，智度宇宙无需因</font>Meta彼岸服务的任何变更、中止和终止向您或者任何第三方承担任何责任。
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">十一</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、期限及终止</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.本用户服务协议的生效日期应为您同意本用户服务协议之日或使用Meta彼岸服务之日中的较早日期，并且无固定的终止日期，除非根据以下情形而终止</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">：</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(1)您丧失民事权利能力或民事行为能力，或丧失其他主体的法律授权</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(2)智度宇宙因为任何原因终止向您提供Meta彼岸服务:</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(3)您主动地不再使用Meta彼岸服务;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(4)其他导致本用户服务协议终止的原因。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.本用户服务协议由于任何原因终止时，您根据本用户服务协议所获得的一切权利及许可将全部同时终止。届时，您将无法继续使用Meta彼岸服务的任何部分，包括但不限于您的用户账号及用户内容。此外，您同意的相关单独附加条款也同时终止。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.你理解并</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">同意</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，本用户服务协议终止后，智度宇宙将继续保存通讨</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">服务</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">收集</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的您的所有信息，并根据</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">用户服务协议使用，除法律法规明确规定的情形外，智度宇宙无义务向您或者任何第三方披露您账号中的任何</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.在本用户服务协议终止后，智度宇宙仍然可以依据相关法律法规及本用户服务协议向您追究违反法律法规或违反本用户服务协议行为的责任，本用户服务协议中关于个人信息保护知识产权、担保与保证、责任承担免责及责任限制、法律适用和争议解决的条款始终有效。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">十</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">二</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">、其他</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">.</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">通知及送达</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">你理解并同意，智度宇宙就有关</font>Meta彼岸服务的任何通知可通过如下任一途径向您发送:在软件及网站张贴一般
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">通知</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;向您提供的电子邮件地址发送电子邮件;向您提供的移动通讯号码发送短信通知;向您提供的通讯地址以平信形式</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">寄送</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">书面通知。前述通知自发布或发送之日即视为已经对您送达，您理解并同意您有义务查收任何上述通知。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.可分割性</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如果本用户服务协议的任何约定与适用的法律法规冲突的，以适用的法律法规的规定为准。如本用户服务协议中的任何约定无论因何种原因无效或不可执行，其在不影响其他约定的情况下从本用户服务协议中被移除，各方应以具有相同</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">效力</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的有效</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">可执行</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">且最</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">能体现本用户服务协议内容和</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">意图</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的条款</font>(或部分)予
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">以</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">替换。本用户服务协议中的其余约定仍应保持有效并且</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">具</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">有完全的法律拘束力。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.转让</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">未经智度宇宙的事先书面同意，您不得向任何第三方转让本用户服务协议及其项下的任何权利和义务。智度宇宙有权</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">随时</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在未取得你同意的情况下向以下各方全部成部分地转让本用户服务协议</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">：</font>&#9312;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙的子公司或者关联</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>&#9313;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙的公司股权、业务或资产的收购方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">或者并购交易中的继承人。任何违反本用户服务协议的转让行为均是无效的。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.完整性</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">用户服务协议</font>(包括补充条款
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">附加条款及其他相关条款</font>)构成你与智度宇宙之间就访向和使用
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">服务的全部协议和理解，并取代您与智度宇宙先前存在的关于前述问题的任何及全部的协议或理解。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">5.弃权</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">除非智度宇宙以书面方式确认并同意，智度宇宙未履行本用户服务协议中任何权利或条款的行为不构成智度宇宙对相关权利或条款的放弃。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">6.适用法律及争议解决</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本用户服务协议的订立、效力、解释、履行、修改和终止，访问和适用</font>Meta彼岸服务以及争议的解决均适用中国法律。因访问和使用Meta彼岸服务而发生的任何争议，双方应首先通过友好协商的方式加以解决。协商不成时，任何一方均可向智度宇宙所在地
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">广州市花都区</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="宋体">人民法院</font>
            </span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">提起诉讼。因访问和使用</font>Meta彼岸服务而发生任何争议或任何争议正在进行诉讼时，除争议的事项外，双方仍应继续行使各自的其他权利并履行各自的其他义务。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">7.标题</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本用户服务协议的所有标题仅为阅读方便，本身并无实际涵义，不得作为本用户服务协议涵义解释的依据</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">8.语言</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本用户服务协议以中文书就。智度宇宙和您之间的全部法律关系由本用户服务协议的中文版确定。如果中英文两</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">个版本存在任何冲突之处，则以中文版本规定为准</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">9.如何联系我们</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta彼岸的成长离不开各方用户达人的共同努力，我们非常感谢您对Meta彼岸数据更新、使用反馈方面的贡献。您可以通过service@</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">genimouschain.c</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">n反馈您对meta彼岸产品的建议以及在使用过程中遇到的问题，以帮助我们优化产品功能及服务，使更多用户更加便捷地使用我们的产品和服务。关于本用户服务协议的问题，您可以通过如下联络方式同我们联系:</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">中国北京市</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">西城区西绒线胡同</font>5
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">号霱公府</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">广州智度宇宙技术有限公司</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">邮政编码</font>:100000
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为保障我们高效外理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您的身份后处理您的请求</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">附录</font>1:定义
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本用户服务协议中使用的特定词语，具有如下含义</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">（</font>1）
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;我们&#8221;或</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8221;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，指</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">广州智度宇宙技术有限公司宇宙；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">（</font>2）&#8220;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8221;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，指使用</font>Meta彼岸的产品和服务的用户
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">（</font>3）&#8220;平台&#8221;，指智度宇宙开发运营的数字藏品的发行、信息展示和交易的平台。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">（</font>4）&#8220;数字藏品&#8221;，指使用智度宇宙区块链技术进行唯一标识的经数字化的特定虚拟作品、艺术品等，包括但不限于数字图片、音乐、视频、
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">模型、雕塑等各种形式的虚拟数字藏品。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">（</font>5）&#8220;发行方&#8221;，指在平台发行数字藏品并向您销售和提供数字藏品的实体或个人。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">（</font>6）&#8220;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙关联方、合作方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8221;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，指我们的关联公司、投资公司、合作伙伴及其他受信任的第三方供应商、服务商及代理商。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">（</font>7）
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;中国&#8221;或&#8220;中国境内&#8221;，指中华人民共和国大陆地区，仅为本用户服务协议之目的，不包含香港特别行政</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">区、澳门特别行政区和台湾地区</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">（</font>8）数字藏品合同，指在您确认所购买数字藏品的名称、介绍、发行方、价格、数量及
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/或其他限制性要求等重要事项，并对是否购买数字藏品做出独立决策和判断</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">后，提交订单并在规定时限内完成支付后，您和我们就有关前述数字产品的知识产权授权和</font>/或买卖等民事关系形成的合同关系。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">再次感谢您对</font>Meta彼岸的信任和使用!&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin: 5px 0;mso-char-indent-count:37.0000;mso-pagination:widow-orphan;text-align:right;"
        >
          <font face="宋体">广州智度宇宙技术有限公司</font>
        </p>
        <p
          class=MsoNormal
          style="margin: 5px 0;mso-char-indent-count:37.0000;mso-pagination:widow-orphan;text-align:right;"
        >
          <font face="宋体">2022年7月10日更新</font>
        </p>
        <p
          class=MsoNormal
          style="margin: 5px 0;mso-char-indent-count:37.0000;mso-pagination:widow-orphan;text-align:right;"
        >
          <font face="宋体">2022年7月10日更新</font>
        </p>
      </div>
    </div>
    <div v-if="$route.query.type == 'policy'">
      <div class="Section0">
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">欢迎您使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸提供的服务</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">!</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙公司</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本隐私政策中指广州智度宇宙技术有限公司，以下亦称</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8221;)</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">非常重视您的隐私保护和个人信息保护。本隐私政策适用于您通过任何方式对</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸提供的各项服务的访问和使用。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您可以通过</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸实现虚拟空间多人互动与交流</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">以下统称：</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸产品或服务</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8221;)</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">。我们仅会处理您使用产品或服务所需的个人信息。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本隐私政策适用于您通过</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">PC</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">端、移动端</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">包括但不限于</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">App</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">VR</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">设备端</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">等方式来访问和使用我们的产品和服务。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸包括但不限于</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">iOS</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Android</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、</font>pico、奇遇
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">等多个应用版本，请您在使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸时选择与所安装终端设备相匹配的版本。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸可能获得的个人信息取决于您所使用的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸应用版本、您所使用的终端设备的具体类型</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">版本以及您所使用的功能。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本隐私政策旨在帮助您了解我们会收集哪些个人信息，为什么收集这些个人信息，会利用这些个人信息做些什么及如何保护这些个人信息。我们会遵循隐私政策收集和使用您的个人信息，但不会仅因您授权同意本隐私政策而采用强制捆绑的方式收集您全部的个人信息。我们会严格按照法律法规要求，在特定情况下征求您的单独同意。除本隐私政策之外，我们还可能会通过产品页面告知等方式向您说明我们处理您个人信息的规则，具体业务功能对应的个人信息处理情况将以具体规则为准，具体规则与本隐私政策具有相同效力。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">除非法律、行政法规另有规定外，针对我们如何处理您的个人信息，您享有知情权和决定权，有权限制或者拒绝我们或其他方对您的个人信息进行外理；同时，您有权要求我们对我们向您提供的个人信息外理规则进行解释说明。请您在使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸及相关产品和服务前仔细阅读并理解本隐私政策，以便做出适当的选择。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本隐私政策将帮助您了解以下内容</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们收集的信息及相应的使用目的</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们如何使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Cookie</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">和同类技术</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们如何共享转让、公开披露您的个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们如何保存及保护您的个人信息</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">5.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您的权利</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">6.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们如何处理未成年人的个人信息</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">7.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">本政策如何更新</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">8.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如何联系我们</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们深知个人信息对您的重要性，也深知为您的信息提供有效保护是我们业务健康可持续发展的基石。感谢您对</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的使用和信任</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">!</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们致力于维持您对我们的信任，恪守以下原则。保护您的个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们郑重承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们收集的信息及相应的使用目的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们将遵循合法、正当、必要的原则，收集和便用您的个人信息。具体而言，我们出于为您提供相应的业务功能而收集并处理您的个人信息。相应功能中，如果您提供的是他人个人信息，请您确保已取得相关主体的授权，我们保留随时核查该等个人信息合法来源的权利。</font>&nbsp;<font face="宋体">您需要向我们提供个人信息以供我们实现</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的基本业务功能，为实现基本业务功能所必需的信息为必要个人信息，若您拒绝提供这些必要个人信息，我们将无法向您提供该基本业务功能及相应服务。在基本业务功能之外，您可以自主选择向我们提供部分个人信息以供我们实现</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的扩展业务功能。我们将向您告知使用的目的并基于您的授权同意或履行您选择的扩展业务功能必需的目的处理这些个人信息。您拒绝提供该等扩展业务功能信息，不会影响您使用我们的基本业务功能，但如您拒绝提供实现扩展业务功能必需的个人信息，我们将无法为您提供扩展业务功能。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为方便您的理解，我们将所提供的业务功能、处理目的及其所需个人信息如下所列</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.1&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">注册登录帐号</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您可以通过手机号创建</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸通用帐号，并且您可以完善您的账号信息。</font>&nbsp;<font face="宋体">当注册、登录</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸通用帐号时，您需向我们提供您的<span class="important">手机号码</span>。您可以选择设置账号名称、头像、登录密码、帐号绑定邮箱。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为了满足法律法规的强制性要求、出于提供产品或服务所必需或者基于您对增强账号保护级别的需求，在您使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸某些服务时或您有进一步需求时，您需提供您的<span class="important">真实姓名、身份证号</span>等完成账号实名认证。如您不注册帐号、登录</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸，会妨碍您使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的大部分业务功能。为了验证您提供信息的准确性和完整性，或保障您的帐号安全，我们可能会将您向</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸提供的实名信息与法律法规允许的机构、政府机关授权的机构、合法留存您信息的第三方机构所保存的您的对应信息进行比对核验。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.2&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">语音对话服务</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸为您提供了语音对话服务。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">若您使用语音对话服务，我们会申请您<span class="important">设备系统的麦克风</span>权限。在经您授权同意后，我们会收集您的语音信息，以便</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">以用于语音实时聊天。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您可以随时通过取消系统授权</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸获取您的<span class="important">麦克风权限</span>，停止我们对您的语音信息的收集，之后您可能将无法使用上述基于麦克风权限提供的相关服务或功能，或者无法达到上述基于麦克风权限提供的相关服务拟达到的效果，但不会影响您正常使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的其他不基于上述权限即可实现的业务功能。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.3&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">新服务和功能运营及客服处理</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸会不时针对新推出的服务和功能发起运营活动，如推出的新服务需要处理您的个人信息或申请设备系统权限，且此前未经过您的授权同意，我们会另行获得您的同意，其中，如涉及敏感个人信息的处理，我们会另行获得您的单独同意。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如果您参加相关运营活动，我们可能会通过运营活动界面收集包括但不限于您的<span class="important">身份信息、联系方式交易帐号类信息</span>，以便与您取得联系、向您发运营奖励等，若您不参加相关运营活动则无需提供前述信息。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如您对于我们提供的产品服务有疑问或需要客服介入进行争议外理时，为了验证您的身份，定位您的问题，可能需要您提供您的<span class="important">身份信息</span>进行核验。为了保障通话质量，确保纠纷得到妥善解决，我们可能会保有您与我们的沟通记录，包括通话记录、您提供的与您的争议解决相关的信息，您使用或提供的<span class="important">联系方式</span>。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.4&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">消息通知</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">请您知悉并同意，对于您在使用产品及</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">或服务的过程中提供的您的<span class="important">联系方式</span></font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span class="important">(</span></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体"><span class="important">例如</span></font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span class="important">:</span></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体"><span class="important">联系电话</span></font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span class="important">)</span></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知身份验证、安全验证、用户使用体验调研等用途。此外，我们也可能会以短信、电话的方式，为您提供可能服务、功能或活动等商业性信息，如您不愿接受这些信息，可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.5&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">保证服务安全、优化和改善服务目的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">当您使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸时，为了保障您正常使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸服务，更准确定位并解决您在使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸服务时遇到的问题，改进及优化</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的服务体验，保障您的账号安全，预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您我们或我们的关联公司、合作伙伴及社会公众的合法权益。我们会收集您的<span class="important">设备信息、日志信息及其他</span>与登录环境相关的信息。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">请您理解，当您将处于设备屏幕中</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸切换到设备后台运行时，由于部分设备机型及系统原因，您此前使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸相关功能而产生的信息收集行为，可能无法立即停止，从而导致短暂的后台信息收集行为。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.6&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">保证服务安全、优化和改善服务目的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">当您使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸时，为了保障您正常使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸服务，更准确定位并解决您在使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸服务时遇到的问题，改进及优化</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的服务体验，保障您的账号安全，预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护你、我们或我们的关联公司、合作伙伴及社会公众的合法权益，我们会收集您的<span class="important">设备信息日志信息及其他</span>与登录环境相关的信息。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">请您理解，当您将处于设备屏幕中</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸切换到设备后台运行时，由于部分设备机型及系统原因，您此前使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸相关功能而产生的信息收集行为，可能无法立即停止，从而导致短暂的后台信息收集行为。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.7&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">间接收集信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为共同向您提供产品</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">服务或改进产品服务的质量或出于对产品和服务安全性的考量等合理需要，我们可能按照法律法规的规定或基于您的有效授权从</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸平台、关联公司、合作伙伴及其他受信任的第三方供应商处接收您的<span class="important">个人信息及其他信息</span>。我们将事先要求个人信息提供方说明个人信息来源，并对其个人信息来源的合法性进行确认，同时，我们会了解个人信息提供方已获得的个人信息处理的授权同意范围，如开展业务所需进行的个人信息处理活动超出已获得的授权同意范围的，我们将在获取个人信息后的合理期限内或处理个人信息前，征得您的明示同意，或通过个人信息提供方征得您的明示同意。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.8&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">事先征得授权同意的例外</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体"><span class="important">请注意</span></font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span class="important">:</span></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体"><span class="important">在以下情形中，收集使用个人信息无需事先征得您的授权同意</span></font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span class="important">:</span></span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#9312;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">与国家安全、国防安全直接相关的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#9313;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为订立、履行个人作为一方当事人的合同所必需为履行法定职责或者法定义务所必需</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#9314;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#9315;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">与犯罪侦查起诉、审判和判决执行等直接有关的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#9316;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#9317;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">依照法律法规的规定在合理的范围内收集您自行向社会公众公开或其他已经合法公开的个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#9318;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">依照法律法规的规定在合理的范围内从合法公开披露的信息中收集您的个人信息，如合法的新闻报道、政府信息公开等渠道；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#9319;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#9320;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#9321;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">法律法规规定的其他情形。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.9&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">个人信息的匿名化处理</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在不公开披露、对外提供您个人信息的前提下，智度宇宙公司有权对匿名化处理后的用户数据库进行挖掘、分析和利用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">包括商业性使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">，有权对产品</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">服务使用情况进行统计并与公众</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">第三方共享匿名化处理后的统计信息。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.10&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">设备权限调用</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在提供服务过程中，为了保证您能正常使用产品功能，我们会向系统申请您设备的以下系统设备权限，申请前我们会征询您的同意，您可以选择</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">允许</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">"</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">或</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">禁止</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8221;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">权限申请。经过您的授权后我们会开启相关权限，您可以随时在系统中取消授权，您取消授权会导致您无法使用相关的业务功能，但不会导致您无法使用其他业务功能。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">各项业务功能对系统隐私权限的调用情况如下</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <table
          class=25
          border=1
          cellspacing=0
          style="border-collapse:collapse;margin-left:1.1500pt;mso-table-layout-alt:fixed;border:none;mso-border-left-alt:1.0000pt solid rgb(255,255,255);mso-border-top-alt:1.0000pt solid rgb(255,255,255);mso-border-right-alt:1.0000pt solid rgb(255,255,255);mso-border-bottom-alt:1.0000pt solid rgb(255,255,255);mso-border-insideh:1.0000pt solid rgb(255,255,255);mso-border-insidev:1.0000pt solid rgb(255,255,255);mso-padding-alt:0.0000pt 0.0000pt 0.0000pt 0.0000pt ;"
        >
          <tr style="height:11.0000pt;">
            <td
              width=78
              valign=top
              style="width:78.0000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:1.0000pt solid #cccccc;mso-border-left-alt:0.5000pt solid #cccccc;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:1.0000pt solid #cccccc;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p
                class=MsoNormal
                style="mso-pagination:widow-orphan;text-align:left;"
              ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:0.0000pt;">设备权限</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=283
              valign=top
              style="width:283.5000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:1.0000pt solid #cccccc;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p
                class=MsoNormal
                style="mso-pagination:widow-orphan;text-align:left;"
              ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:0.0000pt;">业务功能</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=77
              valign=top
              style="width:77.9500pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:1.0000pt solid #cccccc;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p
                class=MsoNormal
                style="mso-pagination:widow-orphan;text-align:left;"
              ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:0.0000pt;">权限授权方式</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=63
              valign=top
              style="width:63.8000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:1.0000pt solid #cccccc;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p
                class=MsoNormal
                style="mso-pagination:widow-orphan;text-align:left;"
              ><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:0.0000pt;">是否可关闭</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
          </tr>
          <tr style="height:13.0000pt;">
            <td
              width=78
              valign=top
              style="width:78.0000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:1.0000pt solid #cccccc;mso-border-left-alt:0.5000pt solid #cccccc;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span class="important">存储</span></span></span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=283
              valign=top
              style="width:283.5000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">存储应用数据、缓存文件、场景资源等</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=77
              valign=top
              style="width:77.9500pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">弹窗提示授权</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=63
              valign=top
              style="width:63.8000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">是</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
          </tr>
          <tr style="height:13.0000pt;">
            <td
              width=78
              valign=top
              style="width:78.0000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:1.0000pt solid #cccccc;mso-border-left-alt:0.5000pt solid #cccccc;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span class="important">麦克风、摄像头</span></span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=283
              valign=top
              style="width:283.5000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.0000pt;mso-font-kerning:1.0000pt;">提供音视频交互功能和服务,实现音频或视频通话功能（在终端用户授权后获取）</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=77
              valign=top
              style="width:77.9500pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">弹窗提示授权</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=63
              valign=top
              style="width:63.8000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">是</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
          </tr>
          <tr style="height:13.0000pt;">
            <td
              width=78
              valign=top
              style="width:78.0000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:1.0000pt solid #cccccc;mso-border-left-alt:0.5000pt solid #cccccc;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span class="important">设备唯一识别码</span></span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=283
              valign=top
              style="width:283.5000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'Arial Unicode MS';color:#CCCCCC;font-size:10.0000pt;mso-font-kerning:1.0000pt;">收集客户端设备活跃数据、自定义事件统计、错误信息，以便于分析和优化产品功能</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=77
              valign=top
              style="width:77.9500pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">弹窗提示授权</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td
              width=63
              valign=top
              style="width:63.8000pt;padding:4.0000pt 4.0000pt 4.0000pt 4.0000pt ;border-left:none;mso-border-left-alt:none;border-right:1.0000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;border-top:none;mso-border-top-alt:0.5000pt solid #cccccc;border-bottom:1.0000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;"
            >
              <p class=MsoNormal><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">是</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
          </tr>
        </table>
        <p
          class=MsoNormal
          style="text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您可以随时在设备的系统设置选择关闭部分或全部权限，从而拒绝</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸收集相应的个人信息。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请您参考设备及系统开发方说明或指引。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">1.11&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">其他您另行同意的目的</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">当我们要将信息用于本隐私政策未载明的其它用途时，会事先征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们如何共享转让、公开披露您的个人信息以及所接入的第三方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们的某些服务将由授权合作伙伴提供，仅为实现本隐私政策中声明的目的。我们可能会与合作伙伴共享你的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的其享你的个人信息，并且只会共享提供服务所必要的个人信息，除另行获得你的授权或法律法规另有要求。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.1&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">共享</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">除非经过您本人事先单独同意或符合其他法律法规规定的情形，我们不会向除智度宇宙公司以外的第三方共享您的个人信息，但经过处理无法识别特定个人且不能复原的除外。对我们与之共享个人信息的公司组织和个人，我们会对其数据安全环境进行调查，与其签署严格的保密协定，要求他们按照已发采取保密和安全措施来处理个人信息。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在下列情况下，我们可能会与以下第三方共享您的个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.1.1&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">第三方产品或服务提供商</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸接入了丰富的第三方服务。当您使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸接入的第三方服务时，可能需要提交您的个人信息。我们将基于您的有效授权或在订立、履行个人作为一方当事人的合同所必需的情况下将该您的个人信息提供给为您提供服务的第三方。关于第三方服务具体如何收集、使用您的个人信息，建议您参考第三方服务的相关服务协议及隐私政策。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">当您参加第三方提供的营销活动或服务内容时，基于服务需要可能需要您提供<span class="important">账号昵称、头像、姓名、联系方式</span>等信息，我们会经过您的单独同意或在第三方为您提供营销活动或服务内容所必需时，向第三方共享您的个人信息，以保障第三方能够顺利为您提供服务或委托第三方及时向您兑现奖励。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在为您提供营销活动或服务内容所必需的情况下，如您拒绝共享，第三方将无法为您提供服务或拒绝您的参与。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.1.2</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">广告主</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">广告服务商</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们可能会将设备标识符、网络信息、广告展现</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">点击、广告转化相关信息与广告主</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">广告服务商进行共享以帮助其可在不识别您个人身份的前提下，评估广告效果、提升广告有效触达率。未经您有效授权，我们不会将可以直接识别您个人身份的信息与广告主</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">广告服务商共享。如您主动为特定广告主留下您的联系方式地址信息等个人信息以供其与您取得联系，广告主将直接处理您的个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.1.3</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">关联公司或同一帐号体系使用方</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们与我们的关联公司或同一账号体系使用方之间具备高度一致的数据安全保障措施和管理水平。为了共同向您提供服务、提高您使用产品的安全性和便利性、保障一致性的服务体验，在遵循法律要求的前提下，我们会在关联公司或同一账号体系使用方之间共享您的个人信息。在此情况下，我们只会共享必要的个人信息，如果我们共享您的敏感个人信息或接收方改变个人信息的使用及外理目的，将再次征求你的授机同意。</font>&nbsp;<font face="宋体">对于同一账号体系使用方，为了履行监管要求，保障账号安全性或进行一致化的展示，基于您的有效授权，我们可能会共享您的帐号昵称、头像绑定状态绑定手机号信息及账号基础信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.2.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">转让</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们不会将您的个人信息转让给除智度宇宙公司外的任何公司组织和个人，但以下情况除外</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">事先获得您的明确授权或同意</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">满足法律法规、法律程序的要求或强制性的政府要求或司法裁定</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如果我们或我们的关联公司涉及合并、分立、清算资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性、向您告知接收方的名称或姓名和联系方式，并尽最大可能确保新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.3.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">公开披露</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们仅会在以下情况下，公开披露您的个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">获得您的单独同意</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">基于法律法规、法律程序诉讼或政府主管部门强制性要求下。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">对违规账号、违规行为进行处罚公告时，我们会披露相关账号的信息，这些信息不可避免包含账号相关认证信息及违规情况。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.4</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">共享转让公开披露个人信息时事先征得授权同意的例外</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在以下情形中，共享、转让、公开披露个人信息无需事先征得个人信息主体的授权同意</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">与国家安全、国防安全直接相关的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为订立、履行个人作为一方当事人的合同所必需为腾行法定职责或者法定义务所必需与公共安全、公共卫生、重大公共利益直接相关的。例如</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">与犯罪侦查起诉、审判和判决执行等直接相关的出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">依照法律法规的规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息，例如</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">合法的新闻报道、政府信息公开等渠道等</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">法律、行政法规另有规定的情形。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.5</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们所接入的第三方软件开发工具包</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(SDK)&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为了适配在不同手机设备上使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸以及第三方平台服务，实现您的手机设备身份认证、语音指令、语音交流，</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸接入了第三方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">SDK</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">。上述第三方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">SDK</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">提供方包括手机设备厂商、社交平台、技术平台等。部分第三方</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">SDK</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">可能会调用您的设备权限、获取您的相关信息，以便您在不同的手机设备或第三方平台上正常使用相应功能，不同的第三方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">SDK</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">所调用的设备权限类型以及所获得的信息可能不同，可能包括获取您的位置，读</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">写您的外部存储卡，读取您的手机状态和身份，查看</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">WLAN</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">连接、检索正在运行的应用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">蓝牙信息。关于第三方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">SDK</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">具体调用的设备权限类型，以及如何收集、使用您的个人信息，建议您参考第三方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">SDK</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的相关服务协议及隐私政策。如果您希望进一步了解我们所接入的第三方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">SDK</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">情况，请您阅读第三方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">SDK</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">情况说明，</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们集成的第三方</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">SDK</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">详见附件一。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">2.6</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">委托处理与共同处理</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在委托处理与共同处理场景下，我们会按照法律法规要求与合作方签署符合法律法规要求的相关协议并履行相关个人信息保护义务。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们如何保存及保护您的个人信息</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.1&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">保存期限</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们将在提供服务所需的期限内保存您的个人信息，但法律法规对保存期限另有规定，您同意留存更长的期限、保证服务的安全与质量、实现争议解决目的、技术上难以实现等情况下，在前述保存期限到期后，我们将依法、依约或在合理范围内延长保存期限。</font>&nbsp;<font face="宋体">在超出保存期限后，我们将根据法律规定删除您的个人信息或进行匿名化处理。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.2&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">保存地域</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。如您的个人信息可能会被转移到您使用产品或服务所在国家</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">地区的境外管辖区，或者受到来自这些管辖区的访问，我们会严格履行法律法规规定的义务并按照法律规定事先获得您的单独同意。此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会按照中国现行法律的规定传输您的个人信息，并会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意或者在跨境数据转移之前实施数据去标识化等安全举措。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.3&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">安全措施</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们会以</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">最小化</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8221;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">原则收集、使用存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们非常重视信息安全。我们成立了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件外首机制等，我们会采取话当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止您的的信息丢失、遭到被未经授权的访问、公开披露、使用、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们会使用受信赖的保护机制防止数据遭到恶意攻击。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们会对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息，若有违反保密协议的行为，会被立即终止与智度宇宙公司的合作关系，并会被追究相关法律责任。对接触个人信息人员在离岗时也提出了保密要求。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们提醒您注意，互联网并非绝对安全的环境，当您通过</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸中嵌入的第三方社交软件、电子邮件、短信等与其他用户交互您的地理位置或行踪轨迹信息时，不确定第三方软件对信息的传递是否完全加密，注意确保您个人信息的安全。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的产品和</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">或服务时所用的系统和通讯网络，有可能在我们控制之外的其他环节而出现安全问题。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为最特大安全事件，一经发生将启动公司最高级别的紧急预案，由安全部、公共事务部、法务部等多个部门组成联合应急响应小组处理。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">3.4&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">安全事件通知</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生，若一旦不幸发生，我们将按照最高优先级启动应急预案，组成紧急应急小组，在最短时间内追溯原因并减少损失</font>&nbsp;<font face="宋体">。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的外理措施，您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知短信通知电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的外置情况。请您理解，根据法律法规的规定，如果我们采取的措施能够有效避免信息泄露、算改、丢失造成危害的，除非监管部门要求向您通知，我们可以选择不向您通知该个人信息安全事件。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您的权利</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">按照中国相关的法律、法规、标准，以及其他国家地区的通行做法，我们保障您对自己的个人信息行使以下权利，如您对权利行使有疑问或主张，您也可以通过</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">8</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如何联系我们中的方式与我们取得联系</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.1.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">查阅权</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">原则上您可以通过如下方式查阅您的个人信息、账号信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您可以登录后访问</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">【我的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">-</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">】</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">访问或编辑您的帐户中的个人资料信息或身份认证等。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.2</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">更正及补充权</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">当您发现我们处理的关于您的个人信息有错误或者不完整时，经对您的身份进行验证，且更正不影响信息的客观性和准确性的情况下，您有权对错误或不完整的信息作出更正或补充，您可以自行在</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸内通过</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">【我的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">-</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">】</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">进行更正或补充，或通过反馈与报错等方式将您的更正或补充申请提交给我们。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.3.</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">删除权</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在以下情形中，您可以向我们提出删除个人信息的请求</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如果我们违反法律法规或与您的约定处理您的个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如果我们的处理目的已实现、无法实现或者为实现处理目的不再必要；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如果我们停止提供产品或者服务，或者保存期限已届满如果您撤回同意</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">法律行政法规规定的其他情形。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删险这些信息，请您知晓并理解，如果法律，行政法规规定的或本隐私政策说明的保存期限未届满，或者删除个人信息从技术上难以实现的，我们会停止除存储和采取必要的安全保护措施之外的处理。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.4&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">撤回同意</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您可以在设备系统中直接关闭摄像头、麦克风电话、存储相册等系统权限，改变同意范围或撤回您的授权。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">当您撤回同意后，我们无法继续为您提供撤回同意所对应的服务，也将不再使用您相应的个人信息。但您撤回同意的决定，不会影响此前基于您的同意而开展的个人信息处理。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.5&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">注销权</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您可注销此前注册的账号。您可以通过以下路径在</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸注销您的帐号</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">【我的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">-</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">设置</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">-</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">注销</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">账号】。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">一旦您注销智度宇宙帐号，将无法使用智度宇宙公司产品的服务，因此请您谨慎操作。我们为了保护您或他人的合法权益会结合您对智度宇宙公司各产品的使用情况判断是否支持您的注销请求。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">具体注销流程及条件请您参考《</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸账号注销协议》</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.6</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">复制权及可携带权</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您有权复制我们收集的您的个人信息。在法律法规规定的条件下，同时符合国家网信部门规定指令和条件的，如果技术可行，您也可以要求我们将您的个人信息转移至您指定的其他主体。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.7</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">提前获知产品和服务停止运营权。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸愿一直陪伴您，若因特殊原因导致</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸产品停止运营，我们将在合理期间内在产品或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对已收集的您的个人信息进行删除或匿名化处理。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.8</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">获得解释的权利</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您有权要求我们就个人信息处理规则作出解释说明。您可以通过第</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">8</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">部分中的联系方式与我们取得联系。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.9&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">权利行使的例外</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">在以下情形中我们可能无法响应您的查阅更正及补充删除复制及可携带知情、决定的请求</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">与国家安全、国防安全直接相关的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">与公共安全、公共卫生、重大公共利益直接相关的与犯罪侦查、起诉、审判和执行判决等直接相关的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们有充分证据表明您存在主观恶意或滥用权利的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">);</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">涉及商业秘密的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">法律行政法规另有规定的情形。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">4.10</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如何响应您的权利请求</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。通过验证后，我们将在十五个工作日内做出答复。如您不满意，还可以通过以下途径投诉</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">【</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">我的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">-</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">联系我们</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">】</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">对于您合理的请求，我们原则上不收取费用，但对多次重复超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">例如，需要开发新系统或从根本上改变现行惯例</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、给他人合法权益带来风险或者非常不切实际</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">例如，涉及备份磁带上存放的信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">的请求，我们可能会予以拍绝。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">5</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们如何处理未成年人的个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙公司非常重视对未成年人信息的保护</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们的产品和服务主要面向成年人。我们只会在受到法律法规分许，父母或其他监护人同意的情况下外理未成年的个人信息。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如您是未满</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">18</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">周岁的未成年人，在使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸产品和服务前，应请您的父母或其他监护人仔细阅读，或在您的分母或其他监护人的监护指导下共同仔细阅读木隐私政策以，并在征得您的父母或其他监护人同意的前提下使用我们的产品和服务，或向我们提供信息。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如果我们发现在未事先获得可证实的父母或其他监护人同意的情况下收集了未满</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">18</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">周岁的未成年人的个人信息，我们将会采取措施尽快册除相关信息。如果任何时候监护人有理由相信我们在未获监护人同章的情况下收集了未成年人的个人信息，造通过</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸中的设置我的帐号意见反馈联系我们，我们会采取措施尽快删除相关数据。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">6</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">隐私政策的修订</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们的隐私政策可能变更。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本隐私政策所做的任何变更。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">对于重大变更，我们会在产品或服务的主要曝光页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您。若您不同意该等变更，请您立即停止使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸产品和服务。本政策所指的重大变更包括但不限于</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等个人信息对外提供、转让或公开披露的主要对象发生变化</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您参与个人信息处理方面的权利及其行使方式发生重大变化</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">个人信息安全影响评估报告表明存在高风险时。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">7</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如何联系我们</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的成长离不开各方用户达人的共同努力，我们非常感谢您对</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸数据更新、使用反馈方面的贡献。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">您可以通过</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">【我的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">-</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;"><span class="msoIns"><ins
                cite="mailto:Janice
                Stephen"
                datetime="2022-07-29T17:32"
              >
                <font face="宋体">联系我们</font>
              </ins></span></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;mso-shading:#cccccc;">
            <font face="宋体">】</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">反馈您对</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸建议以及在使用过程中遇到的问题，以帮助我们优化产品功能及服务，使更多用户更加便捷的使用我们的产品和服务。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">为保障我们高效处理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您的身份处理您的请求</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">向广州市花都区人民法院提起诉讼</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'SimSong
            Bold';color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">附</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">名词解释</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。个人信息包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式通信记录和内容帐号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">个人敏感信息是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账号、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">设备是指可用于使用智度宇宙产品和</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">/</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">或服务的装置，例如桌面设备、平板电脑或智能手机</font>&nbsp;<font face="宋体">设备信息是指您用于安装、运行</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的终性设备的设备属性信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">例如您的硬件型号，操作系统版本，设备配置，国际移动设备身份码</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">IMEI</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、国际移动用户识别码</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">IMSI</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">网络设备硬件地址</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">MAC</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、广告标识符</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">IDFA</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">等唯一设备标识符</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">、设备连接信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">例如蓝牙、浏览器的类型、电信运营商、使用的语言</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">以及设备状态信息</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">例如设备传感器数据，设备应用安装列表</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">日志信息是指我们的服务器所自动记录您在访问</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸时所发出的请求，例如您的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">IP</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">地址、浏览器的类型和使用的语言、硬件设备信息、操作系统的版本、网络运营商的信息、您访问服务的日期、时间、时长等您在使用我们的产品或服务时提供、形成或留存的信息。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">用户画像是指通过收集、汇聚、分析个人信息，对某特定自然人个人特征，如其职业、经济、健康、教育、个人喜好、信用、行为等方面做出分析或预测，形成其个人特征模型的过程。直接使用特定自然人的个人信息，形成该自然人的特征模型，称为直接用户画像。使用来源于特定自然人以外的个人信息，如其所在群体的数据，形成该自然人的特征模型，称为间接用户画像。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">匿名化是指通过对个人信息的技术外理，使得个人信息主体无法被识别，且外理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">智度宇宙平台是指智度宇宙公司旗下各平台服务等网站、程序、服务、工具及客户端。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">关联公司是指</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的经营者广州智度宇宙技术有限公司及其他与智度宇宙公司存在关联关系的公司的单称或合称。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">关联关系是指对于任何主体</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">包括个人公司、合伙企业、组织或其他任何实体</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">)</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">而言，即其直接或间接控制的主体，或直接或间接控制其的主体，或直接或间接与其受同一主体控制的主体。前述</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8220;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">控制</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">&#8221;</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">指通过持有表决权合约或且他方式。直接或间接地拥有对相关主体的管理和决策作出指示或责成他人作出指示的权力或事实上构成实际控制的其他关系。</font>&nbsp;
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">再次感谢您对</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">彼岸的信任和使用</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">!&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">广州智度宇宙技术有限公司</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">更新日期</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:&nbsp;2022</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">年</font>7月10日
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="mso-pagination:widow-orphan;text-align:left;"
        ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">生效日期</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">:&nbsp;2022</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">年</font>7月1
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">0</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="宋体">日</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:0.0000pt;">

          </span></p>
        <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-fareast-font-family:PMingLiU;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-fareast-font-family:PMingLiU;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">附件一</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;Meta</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">彼岸</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">APP</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">涉及个人信息传输的</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">SDK</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">情况表</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#CCCCCC;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <table
          class=25
          border=1
          cellspacing=0
          style="border-collapse:collapse;margin-left:1.1500pt;mso-table-layout-alt:fixed;border:none;mso-border-left-alt:0.5000pt solid #cccccc;mso-border-top-alt:0.5000pt solid #cccccc;mso-border-right-alt:0.5000pt solid #cccccc;mso-border-bottom-alt:0.5000pt solid #cccccc;mso-border-insideh:0.5000pt solid #cccccc;mso-border-insidev:0.5000pt solid #cccccc;mso-padding-alt:0.0000pt 0.0000pt 0.0000pt 0.0000pt ;"
        >
          <tr>
            <td>
              <p
                class=MsoNormal
                align=center
                style="text-align:center;"
              ><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#cccccc;font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;">第三方</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#cccccc;font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;">SDK</span></b><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td>
              <p
                class=MsoNormal
                align=center
                style="text-align:center;"
              ><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#cccccc;font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;">公司名称</span></b><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td>
              <p
                class=MsoNormal
                align=center
                style="text-align:center;"
              ><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#cccccc;font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;">SDK</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#cccccc;font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;">用途</span></b><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td>
              <p
                class=MsoNormal
                align=center
                style="text-align:center;"
              ><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#cccccc;font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;">SDK</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#cccccc;font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;">收集的个人信息类型</span></b><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td>
              <p
                class=MsoNormal
                align=center
                style="text-align:center;"
              ><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#cccccc;font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;">SDK</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#cccccc;font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;">隐私政策链接</span></b><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'Arial Unicode MS';color:#cccccc;font-size:10.0000pt;mso-font-kerning:1.0000pt;">友盟</span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;color:#cccccc;font-size:10.0000pt;mso-font-kerning:1.0000pt;">+SDK</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td>
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'Arial Unicode MS';color:#cccccc;font-size:10.0000pt;mso-font-kerning:1.0000pt;">北京锐讯灵通科技有限公司</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td>
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'Arial Unicode MS';color:#cccccc;font-size:10.0000pt;mso-font-kerning:1.0000pt;">收集客户端设备活跃数据、自定义事件统计、错误信息，以便于分析和优化产品功能</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td>
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'Arial Unicode MS';color:#cccccc;font-size:10.0000pt;mso-font-kerning:1.0000pt;">
                  <span class="important">设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡 IMSI 信息）</span>以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力
                </span>
              </p>
            </td>
            <td>
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'Arial Unicode MS';color:#cccccc;font-size:10.0000pt;mso-font-kerning:1.0000pt;">
                  <a
                    style="word-break: break-all;white-space: pre-wrap;word-wrap: break-word;color: blue;"
                    href="https://developer.umeng.com/docs/147377/detail/210108"
                  >https://developer.umeng.com/docs/147377/detail/210108</a>
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class=MsoNormal><span
                  class="19"
                  style="mso-spacerun:'yes';font-family:宋体;color:#cccccc;font-size:10.0000pt;"
                >声网</span><span
                  class="19"
                  style="mso-spacerun:'yes';font-family:宋体;color:#cccccc;font-size:10.0000pt;"
                >SDK</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td>
              <p
                class=MsoNormal
                style="text-align:left;"
              ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.0000pt;mso-font-kerning:1.0000pt;">上海兆言网络科技有限公司</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td>
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:等线;font-size:10.0000pt;mso-font-kerning:1.0000pt;">提供音视频交互功能和服务,实现音频或视频通话功能（在终端用户授权后获取）</span><span style="font-family:宋体;mso-bidi-font-family:等线;font-size:10.5000pt;mso-font-kerning:1.0000pt;">

                </span></p>
            </td>
            <td>
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'Arial Unicode MS';color:#cccccc;font-size:10.0000pt;mso-font-kerning:1.0000pt;">
                  <span class="important">设备麦克风、摄像头权限、加速度传感器</span>
                </span>
              </p>
            </td>
            <td>
              <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'Arial Unicode MS';color:#cccccc;font-size:10.0000pt;mso-font-kerning:1.0000pt;">
                  <a
                    style="word-break: break-all;white-space: pre-wrap;word-wrap: break-word;color: blue;"
                    href="https://www.agora.io/cn/privacy-policy"
                  >https://www.agora.io/cn/privacy-policy</a>
                </span>
              </p>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
    setTimeout(() => {
      this.$store.commit('HIDE_APPLOADING')
    },1500)
  },
}
</script>

<style scoped>
.important {
  font-size: 11pt;
  color: yellow;
  font-weight: bold;
}
</style>